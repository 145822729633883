// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-admin-admin-mdx": () => import("./../../../../src/Admin/Admin.mdx" /* webpackChunkName: "component---src-admin-admin-mdx" */),
  "component---src-create-create-mdx": () => import("./../../../../src/Create/Create.mdx" /* webpackChunkName: "component---src-create-create-mdx" */),
  "component---src-edit-edit-mdx": () => import("./../../../../src/Edit/Edit.mdx" /* webpackChunkName: "component---src-edit-edit-mdx" */),
  "component---src-fields-fields-mdx": () => import("./../../../../src/Fields/Fields.mdx" /* webpackChunkName: "component---src-fields-fields-mdx" */),
  "component---src-filters-filters-mdx": () => import("./../../../../src/Filters/Filters.mdx" /* webpackChunkName: "component---src-filters-filters-mdx" */),
  "component---src-forms-form-mdx": () => import("./../../../../src/Forms/Form.mdx" /* webpackChunkName: "component---src-forms-form-mdx" */),
  "component---src-inputs-inputs-mdx": () => import("./../../../../src/Inputs/Inputs.mdx" /* webpackChunkName: "component---src-inputs-inputs-mdx" */),
  "component---src-list-list-mdx": () => import("./../../../../src/List/List.mdx" /* webpackChunkName: "component---src-list-list-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-resource-resource-mdx": () => import("./../../../../src/Resource/Resource.mdx" /* webpackChunkName: "component---src-resource-resource-mdx" */),
  "component---src-show-show-mdx": () => import("./../../../../src/Show/Show.mdx" /* webpackChunkName: "component---src-show-show-mdx" */)
}

